import { Box, BoxProps, Chip, ChipProps, TextField, TextFieldProps } from '@mui/material';
import React, { useState } from 'react';

export const ChipsInput: React.FC<{
  value: string[];
  setValue: (val: string[]) => void;
  inputProps?: TextFieldProps;
  chipProps?: ChipProps;
  containerProps?: BoxProps;
}> = props => {
  const { value, setValue, inputProps, chipProps, containerProps } = props;

  const [inpt, setInpt] = useState<string>('');

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="start"
      alignItems="start"
      {...containerProps}
    >
      {value.map((v, idx) => (
        <Chip
          {...chipProps}
          label={v}
          key={idx}
          onDelete={() => {
            const updatedValue = value.filter((_, i) => i !== idx);
            setValue(updatedValue);
          }}
        />
      ))}
      <TextField
        variant="standard"
        fullWidth
        {...inputProps}
        required={false}
        value={inpt}
        onChange={ev => {
          setInpt(ev.target.value);
        }}
        onKeyDown={ev => {
          ev.bubbles = false;
          if (ev.key === 'Enter' || ev.key === ',') {
            ev.preventDefault();
            setValue([...value, inpt]);
            setInpt('');
          }
        }}
      />
    </Box>
  );
};
