import { gql, useQuery } from '@apollo/client';
import React, {
  ReactElement,
  useState,
  useMemo,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CommunityContext } from '../../communityConfigs/communityContextProvider';
import usePassInfo from '../../hooks/usePassInfo';
import { VehicleList } from '../../pages';
import { backendResponse } from '../../types/backendResponse';
import PassInfo from '../../types/passInfo';
import VehicleInfo, { newVehicleInfo } from '../../types/vehicleInfo';
import { backendClient } from '../../utilities/BackendAPI';
import EmployeeSelect from '../employeeSelect/employeeSelect';
import FileInputWithActions from '../fileInputWithActions/fileInputWithActions';
import GenericButton from '../genericButton/genericButton';
import InputField from '../inputField/inputField';
import { PassBuilderDateInputWithFetchDurtionInfo } from '../passBuilderDateRange';
import SearchSelector from '../searchSelector/searchSelector';
import VehicleSelect from '../vehicleSelect/vehicleSelect';
import VehicleTypeSelect from '../vehicleTypeSelect/vehicleTypeSelect';
import style from './vehicleInfoForm.module.css';
// import usePassInfo from '../../../hooks/usePassInfo';

const GET_VEHICLE_INFO_CONFIG = gql`
  query GetVehicleInfoConfig($passInfoId: String!) {
    getVehicleInfoConfig(passInfoId: $passInfoId) {
      success
      error
      data {
        passInfoId
        make
        vehicleModel
        type
        color
        licensePlate
        fleetNumber
        licensePlateState
        primaryDriverName
        licensePrimaryDriver
        driverSelect
        vehicleSelect
      }
    }
  }
`;

const ADDRESS_VALIDATION = gql`
  query AddressValidation($communitySymbol: String!, $address: String!) {
    addressValidation(communitySymbol: $communitySymbol, address: $address) {
      success
      error
      data {
        address
      }
    }
  }
`;
type ADDRESS_VALIDATION_VARS = {
  communitySymbol: string;
  address: string;
};
type ADDRESS_VALIDATION_RES = {
  addressValidation: backendResponse<
    {
      address: string;
    }[]
  >;
};

const defaultItemList: Array<FieldItem> = [
  { title: 'Vehicle Make', name: 'make', value: 'required' },
  { title: 'Vehicle Model', name: 'vehicleModel', value: 'required' },
  {
    title: 'Assigned Person/Driver',
    name: 'driverSelect',
    value: 'disabled',
    displayOption: 'employee-table-select',
  },
  {
    title: 'Vehicle',
    name: 'vehicleSelect',
    value: 'disabled',
    displayOption: 'vehicle-table-select',
  },
  {
    title: 'Vehicle Type',
    name: 'type',
    value: 'optional',
    displayOption: 'vehicle-type-select',
  },
  { title: 'Vehicle Color', name: 'color', value: 'required' },
  { title: 'License Plate', name: 'licensePlate', value: 'required' },
  { title: 'License Plate State', name: 'licensePlateState', value: 'optional' },
  {
    title: 'Year',
    name: 'year',
    value: 'optional',
    displayOption: 'number',
  },
  { title: 'Primary Driver Name', name: 'primaryDriverName', value: 'optional' },
  {
    title: "Primary Driver's License #",
    name: 'licensePrimaryDriver',
    value: 'disabled',
  },
  { title: 'Vehicle Fleet Number', name: 'fleetNumber', value: 'disabled' },
  {
    title: 'Destination',
    name: 'destination',
    value: 'disabled',
    displayOption: 'address-select',
  },
  {
    title: 'Insurance',
    name: 'insuranceFile',
    value: 'disabled',
    displayOption: 'file',
    defaultValue: 'insuranceDocUrl',
  },
  {
    title: 'Registration',
    name: 'registrationFile',
    value: 'disabled',
    displayOption: 'file',
    defaultValue: 'registrationDocUrl',
  },
];

export type vehicleFormData = VehicleInfo &
  Omit<PassInfo, 'vehicle'> & {
    insuranceFile?: File | undefined | null;
    registrationFile?: File | undefined | null;
    regDocFileName?: string | undefined | null;
    insDocFileName?: string | undefined | null;
    vehicle: null;
  };

type VehicleInfoFormProps = {
  data: PassInfo | vehicleFormData;
  idx: number;
  title?: string;
  removeHandler: () => void;
  updateHandler: Dispatch<PassInfo | vehicleFormData>;
  allowRemove?: boolean;
  showDriverForm?: boolean;
  showIsRental?: boolean;
  showViewBtn?: boolean;
  allowCompleteLater?: boolean;
  allowEditAddons?: boolean;
  excludeFields?: string[];
};

type FieldItem = {
  title: string;
  name: string;
  value: string;
  displayOption?:
    | 'text'
    | 'address-select'
    | 'vehicle-type-select'
    | 'file'
    | 'number'
    | 'employee-table-select'
    | 'vehicle-table-select';
  defaultValue?: string;
};

export const VehicleInfoForm = (props: VehicleInfoFormProps): ReactElement => {
  const [open, setOpen] = useState<boolean>(true);
  const {
    idx,
    data,
    allowRemove,
    showViewBtn,
    updateHandler,
    title,
    showDriverForm,
    showIsRental,
    allowCompleteLater,
    allowEditAddons,
    excludeFields,
  } = props;
  const willCompleteLater = useMemo(
    () => data.status === 'incomplete-rental-car',
    [data.status],
  );
  const { t } = useTranslation();
  const { communityId } = useContext(CommunityContext);

  const passInfo = usePassInfo({ passInfoId: data.passInfoId });

  const [itemList, setItemList] = useState<Array<FieldItem>>(defaultItemList);

  const updateItem = useCallback(
    (name: string, value: string) => {
      const res = [...itemList];
      for (let i = 0; i < res.length; i += 1) {
        if (res[i].name === name) {
          res[i].value = value;
          break;
        }
      }
      setItemList(res);
    },
    [setItemList, itemList],
  );

  useEffect(
    () =>
      setItemList(prev => {
        let hasChanged = false;
        const res = [...prev];
        excludeFields?.forEach(ex => {
          const idx = res.findIndex(i => i.name === ex);
          const tmp = res[idx];
          if (tmp && tmp.value !== 'disabled') {
            hasChanged = true;
            tmp.value = 'disabled';
            res[idx] = tmp;
          }
        });
        if (hasChanged) {
          return res;
        }
        return prev;
      }),
    [setItemList, excludeFields],
  );

  useQuery<{
    getVehicleInfoConfig: {
      success: boolean;
      error: string;
      data: {
        passInfoId: string;
        make: string;
        model: string;
        type: string;
        color: string;
        licensePlate: string;
        fleetNumber: string;
      };
    };
  }>(GET_VEHICLE_INFO_CONFIG, {
    fetchPolicy: 'network-only',
    variables: { passInfoId: data.passInfoId },
    onError: e => console.log(e.message),
    onCompleted: d => {
      if (d.getVehicleInfoConfig?.success) {
        Object.entries(d.getVehicleInfoConfig.data || {}).forEach(([key, val]) => {
          updateItem(key, val);
        });
      }
    },
  });

  // const isAddon = (item: any): boolean => data.addons?.indexOf(item.name) !== -1;

  // const toggleAddon = (item: any) => {
  //   const updatedList = [...data.addons];
  //   const position = updatedList.indexOf(item.name);

  //   if (position === -1) {
  //     updatedList.push(item.name);
  //   } else {
  //     updatedList.splice(position, 1);
  //   }

  //   updateHandler((prev) => ({
  //     ...prev,
  //     addons: updatedList,
  //   }));
  // };

  return (
    <div className={style.infoFormBox}>
      <div className={`${style.header} superDarkGrey `}>
        <div className={style.leftPart}>
          <div className={style.btnDown}>
            <GenericButton
              color="transparent"
              shape="drop-down"
              title={open ? '<' : '>'}
              clickHandler={() => setOpen(!open)}
            />
          </div>
          <strong className={`${style.header} textColorWhite `}>
            {title || `${idx + 1}. ${passInfo?.name || ''}`}
          </strong>
        </div>
        {showViewBtn ? (
          <div className={style.view}>
            <Link to={{ pathname: '/vendor/pass-details', state: { data } }}>
              <GenericButton shape="view" title={t('View')} />
            </Link>
          </div>
        ) : null}
        {allowRemove ? (
          <div className={style.btnRemove}>
            <GenericButton
              color="transparent"
              title={t('Remove')}
              clickHandler={() => props.removeHandler()}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {open ? (
        <div>
          {allowCompleteLater && (
            <div className={style.completeBox}>
              <InputField
                inputType="checkbox"
                htmlFor={`incomplete-inpt-${idx}`}
                labelTitle="Complete Information Later"
                checkBox
                checked={data.status === 'incomplete-rental-car'}
                changeHandler={e =>
                  updateHandler({
                    ...data,
                    status: e.target.checked ? 'incomplete-rental-car' : 'incomplete',
                  })
                }
              />
              {willCompleteLater && (
                <div className={style.incompleteWarning}>
                  Notice: By checking this box, you will be required to complete all the
                  requested information later before this pass can be used.
                </div>
              )}
            </div>
          )}
          <div className={style.inputShortBox}>
            {itemList
              .filter(item => item.value !== 'disabled')
              .map((item, key) => {
                switch (item.displayOption) {
                  case 'employee-table-select':
                    return (
                      <div className={style.inputShort} key={key + 'employee'}>
                        {/* control is nested in vehicle select eslint cannot find it */}
                        <label htmlFor={`${idx}-employee-select`}>
                          {`${t(item.title)}${item.value === 'required' ? '*' : ''}`}
                          <EmployeeSelect
                            id={`${idx}-employee-select`}
                            required={
                              willCompleteLater && allowCompleteLater
                                ? false
                                : item.value === 'required'
                            }
                            employee={
                              data.employee || {
                                firstName: data?.vehicle?.primaryDriverName || '',
                                lastName: '',
                                driversLicenseNum:
                                  data?.vehicle?.licensePrimaryDriver || '',
                              }
                            }
                            setEmployee={employee => updateHandler({ ...data, employee })}
                          />
                        </label>
                      </div>
                    );
                  case 'vehicle-table-select':
                    return (
                      <div className={style.inputShort} key={key + 'vehicle'}>
                        {/* control is nested in vehicle select eslint cannot find it */}
                        <label htmlFor={`${idx}-vehicle-select`}>
                          {`${t(item.title)}${item.value === 'required' ? '*' : ''}`}
                          <VehicleSelect
                            id={`${idx}-vehicle-select`}
                            required={
                              willCompleteLater && allowCompleteLater
                                ? false
                                : item.value === 'required'
                            }
                            vehicle={data.vehicle}
                            setVehicle={vehicle => {
                              const updated = { ...data };
                              updated.vehicle = newVehicleInfo({
                                ...vehicle,
                                primaryDriverName: data?.vehicle?.primaryDriverName,
                                vehicleId: data?.vehicle?.vehicleId,
                              });
                              updateHandler(updated);
                            }}
                          />
                        </label>
                      </div>
                    );
                  case 'vehicle-type-select':
                    return (
                      <div className={style.inputShort}>
                        <VehicleTypeSelect
                          htmlFor={`${item.name}-inpt-${idx}`}
                          labelTitle={
                            t(item.title) + (item.value === 'required' ? '*' : '')
                          }
                          inputValue={data[item.name]}
                          changeHandler={e => {
                            updateHandler({
                              ...data,
                              [item.name]: e.target.value,
                            });
                          }}
                          required={willCompleteLater ? false : item.value === 'required'}
                        />
                      </div>
                    );
                  case 'address-select':
                    return (
                      <div className={`${style.inputShort} ${style.searchSelect}`}>
                        <SearchSelector
                          htmlFor={`${item.name}-search-select-${idx}`}
                          title={`${t(item.title)}${
                            item.value === 'required' ? '*' : ''
                          }`}
                          value={data[item.name]}
                          changeHandler={val =>
                            updateHandler({
                              ...data,
                              [item.name]: val,
                            })
                          }
                          required={willCompleteLater ? false : item.value === 'required'}
                          fetch={address =>
                            backendClient
                              .query<ADDRESS_VALIDATION_RES, ADDRESS_VALIDATION_VARS>({
                                query: ADDRESS_VALIDATION,
                                variables: {
                                  address,
                                  communitySymbol: communityId,
                                },
                              })
                              .then(
                                r =>
                                  r.data.addressValidation.data?.map(a => a.address) ||
                                  ([] as string[]),
                              )
                              .catch(() => [] as string[])
                          }
                        />
                      </div>
                    );
                  case 'file':
                    return (
                      <div className={style.inputShort}>
                        <FileInputWithActions
                          id={`${item.name}-inpt-${idx}`}
                          label={`Set ${item.title}`}
                          previousFile={{
                            url: item.defaultValue && data[item.defaultValue],
                            name:
                              // prettier-ignore
                              item.name === 'insuranceFile'
                                ? data.insDocFileName
                                : item.name === 'registrationFile'
                                  ? data.regDocFileName
                                  : '',
                          }}
                          changeHandler={f => {
                            updateHandler({
                              ...data,
                              [item.name]: f,
                            });
                            if (item.name === 'registrationFile') {
                              updateHandler({
                                ...data,
                                regDocFileName: f?.name || '',
                              });
                            }
                            if (item.name === 'insuranceFile') {
                              updateHandler({
                                ...data,
                                insDocFileName: f?.name || '',
                              });
                            }
                          }}
                        />
                      </div>
                    );
                  case 'number':
                    return (
                      <div className={style.inputShort}>
                        <InputField
                          closedInput
                          htmlFor={`${item.name}-inpt-${idx}`}
                          required={willCompleteLater ? false : item.value === 'required'}
                          labelTitle={
                            t(item.title) + (item.value === 'required' ? '*' : '')
                          }
                          inputType="number"
                          inputValue={`${data[item.name]}`}
                          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                            updateHandler({
                              ...data,
                              [item.name]: parseFloat(e.target.value),
                            });
                          }}
                        />
                      </div>
                    );
                  case 'text':
                  // fall through to default
                  default:
                    return (
                      <div className={style.inputShort}>
                        <InputField
                          closedInput
                          htmlFor={`${item.name}-inpt-${idx}`}
                          required={willCompleteLater ? false : item.value === 'required'}
                          labelTitle={
                            t(item.title) + (item.value === 'required' ? '*' : '')
                          }
                          inputType="text"
                          inputValue={data[item.name]}
                          changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                            updateHandler({
                              ...data,
                              [item.name]: e.target.value,
                            });
                          }}
                        />
                      </div>
                    );
                }
              })}
          </div>
          <div className={style.tableContainer}>
            <PassBuilderDateInputWithFetchDurtionInfo
              startDate={data.startDate}
              endDate={data.endDate}
              setter={obj =>
                updateHandler({
                  ...data,
                  ...obj,
                })
              }
              passInfoId={data.passInfoId}
              autoSetDefault={cfg => [0, 1].includes(cfg.type)}
              id={`date-range-${idx}-inpt`}
              required
            />
          </div>
          {/* passInfo
            .priceInfo
            ?.addons
            ?.filter((item) => (allowEditAddons || isAddon(item)))
            ?.map((item, adx: number) => (
              <div className={style.addonContainer}>
                <input
                  type="checkbox"
                  className={style.checkBox}
                  id={`addon-${adx}-inpt-${idx}`}
                  checked={isAddon(item)}
                  onChange={() => toggleAddon(item)}
                  disabled={!allowEditAddons}
                />
                <label
                  className={style.addonLabel}
                  htmlFor={`addon-${adx}-inpt-${idx}`}
                >
                  {(!item.isFree && allowEditAddons) ? `$${item.price} | ` : ''}
                  {item.name}
                </label>
              </div>
            )) */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
VehicleInfoForm.defaultProps = {
  allowRemove: true,
  showDriverForm: false,
  showIsRental: true,
  showViewBtn: false,
  allowCompleteLater: true,
  allowEditAddons: true,
  title: undefined,
};

export default VehicleInfoForm;
