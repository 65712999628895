import {
  FetchResult,
  MutationFunctionOptions,
  useMutation,
  useQuery,
} from '@apollo/client';
import * as Sentry from '@sentry/react';
import { TFunction } from 'i18next';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import PassInfo, { newPassInfo } from '../../../common_lib_front/types/passInfo';
import { newVehicleInfo } from '../../../common_lib_front/types/vehicleInfo';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import { formatDate } from '../../../common_lib_front/utilities/formatDate';
import { PassPaymentCache } from '../wrapper/wrapper';
import {
  DELETE_PASS,
  DELETE_PASS_RES,
  DELETE_PASS_VARS,
  EDIT_VEHICLE,
  EDIT_VEHICLE_RES,
  EDIT_VEHICLE_VARS,
  GET_VEHICLE,
  GET_VEHICLE_RES,
  GET_VEHICLE_VARS,
  SAVE_PASS,
  SAVE_PASS_REQ,
  SAVE_PASS_RES,
  SAVE_VEHICLE,
  SAVE_VEHICLE_RES,
  SAVE_VEHICLE_VARS,
  STEP2_QUERY,
  STEP2_QUERY_RES,
  STEP2_QUERY_VARS,
  STEP_TWO_SUMBIT,
  ADD_PASS,
  ADD_PASS_RES,
  ADD_PASS_VARS,
} from './registerVehicleRequests';

function makePassListProxy(passes: PassInfo[]): PassInfo[] {
  const res: PassInfo[] = [];
  passes.forEach(p => {
    // vehicle proxy
    const vp = p?.vehicle?.isProxy
      ? p.vehicle
      : new Proxy(p.vehicle, {
          get: function (t, p, r) {
            if (p === 'isProxy') return true;
            return Reflect.get(t, p, r);
          },
          set: (target, p, newValue) => {
            // console.log('proxy set');
            Sentry.addBreadcrumb({
              type: 'State vehicle direct edit attempt',
              category: 'Bad vehicle data edit',
              message: 'Bad vehicle data edit',
              data: {
                key: p,
                newValue: JSON.stringify(newValue),
              },
            });
            target[p as any] = newValue;
            return true;
          },
        });
    // pass proxy
    const pp = (p as any).isProxy
      ? p
      : new Proxy(
          { ...p, vehicle: vp },
          {
            get: function (t, p, r) {
              if (p === 'isProxy') return true;
              return Reflect.get(t, p, r);
            },
            set: (target, p, newValue) => {
              Sentry.addBreadcrumb({
                type: 'State pass direct edit attempt',
                category: 'Bad pass data edit',
                message: 'Bad pass data edit',
                data: {
                  key: p,
                  newValue: JSON.stringify(newValue),
                },
              });
              (target as any)[p] = newValue;
              return true;
            },
          },
        );
    res.push(pp);
  });
  if ((res as any).isProxy) return res;
  return new Proxy(res, {
    get: function (t, p, r) {
      if (p === 'isProxy') return true;
      return Reflect.get(t, p, r);
    },
    set: (target, p, newValue) => {
      Sentry.addBreadcrumb({
        type: 'State pass list direct edit attempt',
        category: 'Bad pass list data edit',
        message: 'Bad pass list data edit',
        data: {
          key: p,
          newValue: JSON.stringify(newValue),
        },
      });
      (target as any)[p] = newValue;
      return true;
    },
  });
}

export default function useRegisterVehicle(
  nextHref: string,
  guestName: string,
): {
  redirect: string;
  doSubmit: () => Promise<void>;
  doAddPass: () => Promise<void>;
  alert: string;
  passes: Array<PassInfo>;
  setPasses: Dispatch<SetStateAction<Array<PassInfo>>>;
  doRemovePass: (
    options?: MutationFunctionOptions<DELETE_PASS_RES, DELETE_PASS_VARS> | undefined,
  ) => Promise<FetchResult<DELETE_PASS_RES>>;
  registrationDates: { startDate: string; endDate: string } | null;
  t: TFunction;
  addingPass: boolean;
} {
  // eslint-disable-line indent
  const [_passes, _setPasses] = useState<Array<PassInfo>>([]);
  const { communityId } = useContext(CommunityContext);
  const passes = useMemo(() => makePassListProxy(_passes), [_passes]);
  const [addingPass, setAddingPass] = useState<boolean>(false);
  const setPasses: typeof _setPasses = useCallback(
    action =>
      _setPasses(prev => {
        const res = typeof action === 'function' ? action(prev) : action;
        Sentry.addBreadcrumb({
          type: 'Set pass call',
          category: 'Valid state set',
          message: 'Valid state set',
          data: {
            value: JSON.stringify(res),
          },
        });
        return res;
      }),
    [_setPasses],
  );
  const [redirect, setRedirect] = useState<string>('');
  const [registrationDates, setRegistrationDates] = useState<{
    startDate: string;
    endDate: string;
  } | null>(null);
  const { registrationId } = useParams<{ registrationId: string }>();
  const [alert, setAlert] = useState<string>('');
  const { t } = useTranslation();

  const [doRemovePass] = useMutation<DELETE_PASS_RES, DELETE_PASS_VARS>(DELETE_PASS);

  useQuery<STEP2_QUERY_RES, STEP2_QUERY_VARS>(STEP2_QUERY, {
    variables: { registrationId },
    fetchPolicy: 'no-cache',
    onError: err => {
      Sentry.addBreadcrumb({
        type: 'HTTP request',
        category: 'fetch',
        message: 'Passes failed to fetch by registration id',
        data: {
          error: err.message,
        },
      });
    },
    onCompleted: async d => {
      // leave breadcrumb
      Sentry.addBreadcrumb({
        type: 'HTTP request',
        category: 'fetch',
        message: 'Passes fetched by registration id',
        data: {
          error: d.getPassesByRegistration.error,
          fetchedData: Object.assign({}, d.getPassesByRegistration.data || {}),
        },
      });
      if (
        d.getCommunityRental.success &&
        d.getCommunityRental.data &&
        !d.getCommunityRental.data.guestCanEdit &&
        d.getCommunityRental.data.arrivalDate &&
        d.getCommunityRental.data.departureDate
      ) {
        setRegistrationDates({
          startDate: formatDate(new Date(d.getCommunityRental.data.arrivalDate)),
          endDate: formatDate(new Date(d.getCommunityRental.data.departureDate)),
        });
      }
      const res = d.getPassesByRegistration.data?.map(p => newPassInfo(p)) || [];
      // get vehicle info for each pass
      await Promise.all(
        res.map((element: PassInfo, idx: number) =>
          backendClient
            .query<GET_VEHICLE_RES, GET_VEHICLE_VARS>({
              query: GET_VEHICLE,
              fetchPolicy: 'network-only',
              variables: { passId: element.passId },
            })
            .then(v => {
              res[idx].vehicle = newVehicleInfo({
                primaryDriverName: ['watercolor'].includes(communityId)
                  ? `Wristband ${idx + 1}`
                  : '',
                ...v.data.getVehicle.data,
              });
            })
            .catch(() => {
              // console.log(e);
            }),
        ),
      );
      if (res.some(p => !p.passId)) {
        Sentry.captureMessage(
          `Some passes were fetched without a passId, data: ${JSON.stringify(res)}`,
        );
      }
      Sentry.addBreadcrumb({
        type: 'log',
        message: 'About to set passes from backend requests finish',
      });
      setPasses(res);
      PassPaymentCache.push(res);
    },
  });

  const [doSubmitStep2] = useMutation(STEP_TWO_SUMBIT, {
    onError: () => {
      if (process.env.REACT_APP_DEBUG === 'true') {
        // setRedirect(`/guest/registration/${registrationId || 0}/community-rules`);
        setRedirect(nextHref);
      }
    },
    onCompleted: () => {
      // setRedirect(`/guest/registration/${registrationId}/community-rules`);
      setRedirect(nextHref);
    },
  });

  async function submitInner(p: PassInfo, idx: number): Promise<FetchResult<SAVE_PASS_RES | SAVE_VEHICLE_RES | EDIT_VEHICLE_RES>> {
    // temp id added to some passes for use in pass price display
    if (!p.passId || p.passId.startsWith('temp_id')) {
      Sentry.addBreadcrumb({
        type: 'Generic pass about to be created',
        category: 'Questionable data',
        message: 'data about to be sent to regiterPass',
        data: {
          pass: p,
        },
      });
      Sentry.captureMessage(`New pass about to be created, data: ${JSON.stringify(p)}`, {
        extra: {
          data: p,
        },
      });
      return backendClient
        .mutate<SAVE_PASS_RES, SAVE_PASS_REQ>({
          mutation: SAVE_PASS,
          variables: {
            registrationId,
            passInfoId: p.passInfoId,
            status: p.status,
            startDate: new Date(p.startDate),
            endDate: new Date(p.endDate),
          },
        })
        .then(d => {
          if (d.data?.registerPass.data && d.data.registerPass.data[0]?.passId) {
            backendClient.mutate({
              mutation: SAVE_VEHICLE,
              variables: {
                passId: d.data.registerPass.data[0].passId,
                passInfoId: d.data.registerPass.data[0].passInfoId,
                vehicleInfo: {
                  make: p.vehicle.make,
                  vehicleModel: p.vehicle.vehicleModel,
                  type: p.vehicle.type,
                  color: p.vehicle.color,
                  licensePlate: p.vehicle.licensePlate,
                  fleetNumber: p.vehicle.fleetNumber,
                  isRental: p.vehicle.isRental,
                  primaryDriverName:
                    p.vehicle.primaryDriverName ||
                    (['watercolor'].includes(communityId)
                      ? `Wristband ${idx + 1}`
                      : guestName),
                },
              },
            });
          }
          return d;
        });
    }
    if (!p.vehicle.vehicleId) {
      return backendClient.mutate<SAVE_VEHICLE_RES, SAVE_VEHICLE_VARS>({
        mutation: SAVE_VEHICLE,
        variables: {
          passId: p.passId,
          passInfoId: p.passInfoId,
          vehicleInfo: {
            make: p.vehicle.make,
            vehicleModel: p.vehicle.vehicleModel,
            type: p.vehicle.type,
            color: p.vehicle.color,
            licensePlate: p.vehicle.licensePlate,
            fleetNumber: p.vehicle.fleetNumber,
            isRental: p.vehicle.isRental,
            licensePlateState: p.vehicle.licensePlateState,
            primaryDriverName:
              p.vehicle.primaryDriverName ||
              (['watercolor'].includes(communityId) ? `Wristband ${idx + 1}` : guestName),
            licensePrimaryDriver: p.vehicle.licensePrimaryDriver,
          },
        },
      });
    }
    return backendClient.mutate<EDIT_VEHICLE_RES, EDIT_VEHICLE_VARS>({
      mutation: EDIT_VEHICLE,
      variables: {
        vehicleId: p.vehicle.vehicleId,
        passId: p.passId,
        status: p.status,
        newVehicleInfo: {
          make: p.vehicle.make,
          vehicleModel: p.vehicle.vehicleModel,
          type: p.vehicle.type,
          color: p.vehicle.color,
          licensePlate: p.vehicle.licensePlate,
          fleetNumber: p.vehicle.fleetNumber,
          isRental: p.vehicle.isRental,
          licensePlateState: p.vehicle.licensePlateState,
          primaryDriverName:
            p.vehicle.primaryDriverName ||
            (['watercolor'].includes(communityId) ? `Wristband ${idx + 1}` : guestName),
          licensePrimaryDriver: p.vehicle.licensePrimaryDriver,
        },
      },
    });
  }

  const doSubmit = async () => {
    Promise.all(passes.map((p: PassInfo, idx) => submitInner(p, idx)))
      .then((responses) => {
        let success = true;
        responses.forEach(resp => {
          if (!resp?.data) return;
          
          if ('registerPass' in resp.data) {
            if (!resp.data.registerPass.success) {
              success = false;
              setAlert(resp.data.registerPass.error as string);
            }
          }
          if ('registerVehicle' in resp.data) {
            if (!resp.data.registerVehicle.success) {
              success = false;
              setAlert(resp.data.registerVehicle.error as string);
            }
          }
          if ('editVehicle' in resp.data) {
            if (!resp.data.editVehicle.success) {
              success = false;
              setAlert(resp.data.editVehicle.error as string);
            }
          }
        });
        if (!success) {
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((window as any).registrationLockStepNum >= 3) {
          // setRedirect(`/guest/registration/${registrationId}/community-rules`);
          setRedirect(nextHref);
          return;
        }
        doSubmitStep2({
          variables: {
            registrationId,
            stepNumber: 3,
          },
        });
      })
      .catch(() => {
        setAlert(
          t(
            'Some data may not have been saved. Please try again later or report this issue',
          ),
        );
      });
  };

  const [addPass] = useMutation<ADD_PASS_RES, ADD_PASS_VARS>(ADD_PASS, {
    onError: e => {
      console.error('error', e);
      setAddingPass(false);
    },
    onCompleted: async d => {
      console.log('d.addPassToInvite.data', d.addPassToInvite.data);
      console.log('before pass data');
      if (d.addPassToInvite.data) {
        console.log('after pass data');

        const passDetails = newPassInfo(d.addPassToInvite.data[0].passes[0]);
        passDetails.vehicle = newVehicleInfo({
          primaryDriverName: ['watercolor'].includes(communityId)
            ? `Wristband ${passes.length + 1}`
            : '',
        });
        console.log('after set vehicle data');
        setPasses([...passes, passDetails]);
        console.log('passes====>', passes);

        PassPaymentCache.push(passes);
      }
      if (d.addPassToInvite.error) {
        setAlert(d.addPassToInvite.error);
      }
      setAddingPass(false);

      // setRedirect(`/guest/registration/${registrationId}/community-rules`);
      //setRedirect(nextHref);
    },
  });

  const doAddPass = async () => {
    setAddingPass(true);
    addPass({
      variables: {
        passInfoId: passes[0].passInfoId,
        registrationId,
      },
    });
  };

  return {
    redirect,
    doSubmit,
    doAddPass,
    alert,
    passes,
    setPasses,
    doRemovePass,
    registrationDates,
    addingPass,
    t,
  };
}
